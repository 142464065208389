import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import config from '@/config'

// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'

Vue.use(Router)

const router = new Router({
  routes
})

router.beforeEach((to, from, next) => {
  // NProgress.start()
  
  next()
})

router.afterEach(to => {
  // NProgress.done()
  document.title = config.systemName
  window.scrollTo(0, 0)
})

export default router