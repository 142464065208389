import axios from 'axios'
import qs from 'qs'
import config from '@/config'
import {Message,Loading} from 'element-ui'
import {isArray} from '@/libs/tool'
import {getToken, logout, getApiHost} from '@/libs/util'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

class Http {
	constructor () {
    this.counterPost = 0 
    this.counterGet = 0 
		this.loadingInstance = {}
  }
	destroy () {
    if (this.counterPost > 0) {
      if (--this.counterPost === 0) {
        this.loadingInstance.close()
        //setTimeout(() => this.loadingInstance.close() , 600)
      }
    }
    if (this.counterGet > 0) {
      if (--this.counterGet === 0) {
        NProgress.done()
      }
    }
    // this.counter--
    // if (this.counter === 0) {
    //   NProgress.done()
    //   hasKey(this.loadingInstance) > 0 && setTimeout(() => this.loadingInstance.close() , 600)
    // }
  }
  assign (option) {
    option = Object.assign({
      baseURL: getApiHost(),
      loading: true
    }, option)
    const token = {
      token: getToken()
    }
    if (option.method === `POST`) {
      option.headers = {}
      option.headers['Content-Type'] = `application/x-www-form-urlencoded`
      option.data = Object.assign(option.data === undefined ? {} : option.data, token)
      // Object.keys(option.data).forEach(k => {
      //   if (option.data[k] === null || (isArray(option.data[k]) && option.data[k].length === 0)) {
      //     option.data[k] = ""
      //   }
      // })
      option.data = qs.stringify(option.data)
    } else {
      option.params = Object.assign(option.params === undefined ? {} : option.params, token)
    }
    return option
  }
  request(option) {
    // 合并选项
    option = this.assign(option)
    
    // loading
    if (option.loading) {
      if (option.method === `POST`) {
        if (this.counterPost === 0) {
          this.loadingInstance = Loading.service({
            fullscreen: true
          })
        }
        this.counterPost++
      } else {
        if (this.counterGet === 0) {
          NProgress.start()
        }
        this.counterGet++
      } 
    }

    // 请求
    axios(option).then(res => {
      const code = res.data.code
      const msg = res.data.msg
      const data= res.data.data
      let done
      switch(code) {
  			case -1:
  			done = () => {
  				logout()
  				location.href = config.defaultURL
  			}
        break
        
  			case 0:
  			done = option.error === undefined ? () => {} : option.error
        break
        
  			case 1:
  			done = option.success === undefined ? () => {} : option.success
  			break
      }

  		if (msg === "" || msg === null) {
        done(data)
        this.destroy()
  		} else {
				Message({
					type: code === 1 ? `success` : `error`,
					message: msg,
					duration: 1800,
					onClose: () => {
            done(data)
            this.destroy()
					}
				})
  		}
    }).catch(error => {
			this.destroy()
			Message.error(error.message)
		})
  }
}

export default new Http