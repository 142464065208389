import Container from '@/views/container'
import Empty from '@/views/empty'
export default [
  {
    path: `/`,
    component: () => import('@/views/login'),
    meta: {
      hideInMenu: true
    }
  },
  {
    path: `/home`,
    component: Container,
    meta: {
      title: `主页`,
      icon: `el-icon-cloudy`,
      index: `10`
    },
    children: [
      {
        path: ``,
        component: () => import('@/views/home')
      }
    ]
  },
  {
    path: `/auth`,
    component: Container,
    meta: {
      title: `权限`,
      icon: `el-icon-key`,
      index: `11`
    },
    children: [
      {
        path: `role`,
        component: () => import('@/views/auth/role'),
        meta: {
          title: `角色`,
          index: `11-1`,
          rule: `role`
        }
      },
      {
        path: `rule-view`,
        component: () => import('@/views/auth/rule-view'),
        meta: {
          title: `视图`,
          index: `11-2`,
          rule: `view`
        }
      },
      {
        path: `rule-api`,
        component: () => import('@/views/auth/rule-api'),
        meta: {
          title: `API`,
          index: `11-3`,
          rule: `api`
        }
      }
    ]
  },
  {
    path: `/hr`,
    component: Container,
    meta: {
      title: `人事`,
      icon: `el-icon-user`,
      index: `12`
    },
    children: [
      {
        path: `branch`,
        component: () => import('@/views/hr/branch'),
        meta: {
          title: `部门`,
          index: `12-1`,
          rule: `branch`
        }
      },
      {
        path: `post`,
        component: () => import('@/views/hr/post'),
        meta: {
          title: `职位`,
          index: `12-2`,
          rule: `post`
        }
      },
      {
        path: `grade`,
        component: () => import('@/views/hr/grade'),
        meta: {
          title: `职级`,
          index: `12-3`,
          rule: `grade`
        }
      },
      {
        path: `user`,
        component: () => import('@/views/hr/user'),
        meta: {
          title: `员工`,
          index: `12-4`,
          rule: `user`
        }
      },
      {
        path: `appraise`,
        component: () => import('@/views/hr/appraise'),
        meta: {
          title: `评分`,
          index: `12-5`,
          rule: `appraise`
        }
      }
    ]
  },
  {
    path: `/finance`,
    component: Container,
    meta: {
      title: `财务`,
      icon: `el-icon-money`,
      index: `13`,
    },
    children: [
      {
        path: `flow`,
        component: Empty,
        meta: {
          title: `流水`,
          index: `13-1`
        },
        children: [
          {
            path: `bill`,
            component: () => import('@/views/finance/bill'),
            meta: {
              title: `报账`,
              index: `13-1-1`,
              rule: `bill`
            }
          },
          {
            path: `bill-project`,
            component: () => import('@/views/finance/bill-project'),
            meta: {
              title: `账单`,
              index: `13-1-2`,
              rule: `bill-project`
            }
          },
          {
            path: `debt`,
            component: () => import('@/views/finance/debt'),
            meta: {
              title: `负债`,
              index: `13-1-3`,
              rule: `debt`
            }
          }
        ]
      },
      {
        path: `pandian`,
        component: Empty,
        meta: {
          title: `盘点`,
          index: `13-2`
        },
        children: [
          {
            path: `dashboard`,
            component: () => import('@/views/finance/pandian/dashboard'),
            meta: {
              title: `仪表盘`,
              index: `13-2-1`,
              rule: `dashboard`
            }
          },
          {
            path: `balance`,
            component: () => import('@/views/finance/pandian/balance'),
            meta: {
              title: `资产负债表`,
              index: `13-2-2`,
              rule: `balance`
            }
          },
          {
            path: `profit`,
            component: () => import('@/views/finance/pandian/profit'),
            meta: {
              title: `利润表`,
              index: `13-2-3`,
              rule: `profit`
            }
          },
          {
            path: `cash-flow`,
            component: () => import('@/views/finance/pandian/cash-flow'),
            meta: {
              title: `现金流量表`,
              index: `13-2-4`,
              rule: `cash-flow`
            }
          }
        ]
      },
      {
        path: `setup`,
        component: Empty,
        meta: {
          title: `设置`,
          index: `13-3`          
        },
        children: [
          {
            path: `cate`,
            component: () => import('@/views/finance/cate'),
            meta: {
              title: `业务分类`,
              index: `13-3-1`,
              rule: `cate`
            }
          },
          {
            path: `subject`,
            component: () => import('@/views/finance/subject'),
            meta: {
              title: `科目`,
              index: `13-3-2`,
              rule: `subject`
            }
          },
          {
            path: `subject-group`,
            component: () => import('@/views/finance/subject-group'),
            meta: {
              title: `报表公式`,
              index: `13-3-3`,
              rule: `subject-group`
            }
          },
          {
            path: `project`,
            component: () => import('@/views/finance/project'),
            meta: {
              title: `项目`,
              index: `13-3-4`,
              rule: `project`
            }
          },
          {
            path: `bankcard`,
            component: () => import('@/views/finance/bankcard'),
            meta: {
              title: `资金账户`,
              index: `13-3-5`,
              rule: `bankcard`
            }
          }
        ]
      }
    ]
  },
  {
    path: `/goods`,
    component: Container,
    meta: {
      title: `商品`,
      icon: `el-icon-goods`,
      index: `19`,
    },
    children: [
      {
        path: `goods`,
        component: () => import('@/views/goods/goods'),
        meta: {
          title: `商品`,
          index: `19-1`,
          rule: `goods`
        }
      },
      {
        path: `stock`,
        component: () => import('@/views/goods/in'),
        meta: {
          title: `库存`,
          index: `19-2`,
          rule: `stock`
        }
      }
    ]
  },
  {
    path: `/content`,
    component: Container,
    meta: {
      title: `内容`,
      icon: `el-icon-edit-outline`,
      index: `26`
    },
    children: [
      {
        path: `content-cate`,
        component: () => import('@/views/content/cate'),
        meta: {
          title: `分类`,
          index: `26-1`,
          rule: `content-cate`
        }
      },
      {
        path: `xhs-note`,
        component: () => import('@/views/content/xhs-note'),
        meta: {
          title: `小红书笔记`,
          index: `26-2`,
          rule: `xhs-note`
        }
      }
    ]
  },
  {
    path: `/setup`,
    component: Container,
    meta: {
      title: `设置`,
      icon: `el-icon-setting`,
      index: `20`
    },
    children: [
      {
        path: `brand`,
        component: () => import('@/views/settings/brand'),
        meta: {
          title: `品牌`,
          index: `20-1`,
          rule: `brand`
        }
      }
    ]
  },
  {
    path: `/account`,
    component: Container,
    meta: {
      title: `我的账户`,
      icon: `el-icon-postcard`,
      index: `22`
    },
    children: [
      {
        path: `reset`,
        component: () => import('@/views/account/password'),
        meta: {
          title: `修改密码`,
          index: `22-1`
        }
      }
    ]
  }
]