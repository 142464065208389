import {checkPermission} from '@/libs/util'
export default {
	inserted(el, binding, vnode) {
		const rule = binding.value
		if (rule) {
			//console.log(store.state.role)
			if (!checkPermission(rule)) {
				el.parentNode && el.parentNode.removeChild(el)
			}
		}
	}
}