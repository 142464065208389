import {sessionSave, sessionRead, sessionRemove, oneOf} from './tool'
import config from '@/config'
import http from './http'

export const login = (token, ruleList) => {
	sessionSave(config.tokenName, token)
	sessionSave(config.ruleName, JSON.stringify(ruleList))
}

export const logout = () => {
	sessionRemove(config.tokenName)
	sessionRemove(config.ruleName)
}

export const getToken = () => {
	return sessionRead(config.tokenName) || ''
}

export const getRule = () => {
	return JSON.parse(sessionRead(config.ruleName) || '')
}

export const getApiHost = node => {
	const request = config.request[process.env.NODE_ENV === 'development' ? 'dev' : 'pro']
	return `${request.protocol}${node === undefined ? request.node.api : request.node[node]}.${config.domain}`
}

export const checkPermission = rule => {
	const rules = getRule()
	return oneOf(rule, rules)
} 

export const getUploadToken = (accept) => {
	return new Promise((resolve, reject) => {
		http.request({
			url: `service/upload/token`,
			params: {accept: accept},
			success: (res) => {
				resolve(res)
			}
		})
	})
}