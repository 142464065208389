/**
 * @description 浏览器名称
 * @return {string}
 */
export const getExplorer = () => {
  const ua = window.navigator.userAgent
  const isExplorer = (exp) => {
    return ua.indexOf(exp) > -1
  }
  if (isExplorer('MSIE')) return 'IE'
  else if (isExplorer('Firefox')) return 'Firefox'
  else if (isExplorer('Chrome')) return 'Chrome'
  else if (isExplorer('Opera')) return 'Opera'
  else if (isExplorer('Safari')) return 'Safari'
}

/**
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 * @param {number} num
 * @return {string}
 */
export const getHandledValue = (num) => {
  return (num < 10 ? '0' + num : num).toString()
}

/**
 * @description 值是否在数组中
 * @param {string|number} value
 * @param {array} valueList
 * @return {boolean}
 */
export const oneOf = (value, valueList) => {
  for (let i = 0; i < valueList.length; i++) {
    if (value === valueList[i]) {
      return true
    }
  }
  return false
}

/**
 * @description 2个数组交集
 * @param {array} arr1
 * @param {array} arr2
 * @return {array}
 */
export const getIntersection = (arr1, arr2) => {
  let len = Math.min(arr1.length, arr2.length)
  let i = -1
  let res = []
  while (++i < len) {
    const item = arr2[i]
    if (arr1.indexOf(item) > -1) res.push(item)
  }
  return res
}

/**
 * @description 2个数组并集
 * @param {array} arr1
 * @param {array} arr2
 * @return {array}
 */
export const getUnion = (arr1, arr2) => {
  return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @description session存储
 * @param {string} key
 * @param {string|number} value
 */
export const sessionSave = (key, value) => {
  sessionStorage.setItem(key, value)
}

/**
 * @description session读取
 * @param {string} key
 * @return {string}
 */
export const sessionRead = (key) => {
  return sessionStorage.getItem(key) || ''
}

/**
 * @description session删除
 * @param {string} key
 */
export const sessionRemove = (key) => {
  sessionStorage.removeItem(key)
}

/**
 * @description 本地存储
 * @param {string} key
 * @param {string|number} value
 */
export const localSave = (key, value) => {
  localStorage.setItem(key, value)
}

/**
 * @description 本地读取
 * @param {string} key
 * @return {string}
 */
export const localRead = (key) => {
  return localStorage.getItem(key) || ''
}

/**
 * @description 本地删除
 * @param {string} key
 */
export const localRemove = (key) => {
  localStorage.removeItem(key)
}


/**
 * @description 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * @param {object} obj
 * @param {string} key
 * @return {boolean}
 */
export const hasKey = (obj, key) => {
  if (key) return key in obj
  else {
    let keysArr = Object.keys(obj)
    return keysArr.length
  }
}

/**
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 * @param {object} obj1
 * @param {object} obj2
 * @return {boolean}
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}


/**
* @description 替换obj1存在的值 /a数组 /m混合
* @param {object} obj1
* @param {object} obj2
* @return {object}
*/
export const objReplace = (obj1, obj2) => {
  for (let key in obj1) {
    if (obj2[`_${key}/a`] !== undefined) {// 数组
      obj1[key] = obj2[`_${key}/a`]
    } else if (obj2[`_${key}/s`] !== undefined) {// 字符串
      obj1[key] = obj2[`_${key}/s`]
    } else if (obj2[`_${key}/m`] !== undefined) {// 混合
      obj1[key] = obj2[`_${key}/m`]
    } else if (obj2[`_${key}/i`] !== undefined) {// 整数
      obj1[key] = obj2[`_${key}/i`]
    } else if (obj2[`_${key}/f`] !== undefined) {// 小数
      obj1[key] = obj2[`_${key}/f`]
    } else if (obj2[key] !== undefined) {
      obj1[key] = obj2[key]
    }
  }
  return obj1;
}


/**
 * @description 是否为数组
 * @param {Array} arr
 * @return {Boolean}
 */
export const isArray = (arr) => {
  return typeof arr === 'object' && arr.constructor === Array
}

/**
 * @description 获取唯一ID
 * @return {string}
 */
export const uniqid = () => {
  let s = []
  var hexDigits = "0123456789abcdef"
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = "4"
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = "-"
  return s.join("")
}

/**
 * @description 当前日期
 * @param {int} yesterdayHourLine 如果小于这个时间点则显示昨天的日期
 * @return {string}
 */
export const getTodayDate = (yesterdayHourLine = 0) => {
  const date = new Date();
  const currHour = date.getHours()
  if (currHour < yesterdayHourLine) {
    date.setTime(date.getTime()- 24 * 60 * 60 * 1000)
  }
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  return `${year}-${month}-${day}`;
}


