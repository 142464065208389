import Vue from 'vue'
import App from './App'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './style.less'
Vue.use(ElementUI)

import config from './config'
import http from './libs/http'
Vue.prototype.$config = config
Vue.prototype.$http = http

import permission from './directive/permission'
Vue.directive('permission', permission)

new Vue({
  el: '#app',
  router,
  render: h => h(App)
});