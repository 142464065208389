<template>
  <div>
    <!--header-->
    <div class="header">
      <!-- <div class="logo"><img :src="logo"></div> -->
      <div class="nav"></div>
      <div class="avatar">
        <el-dropdown>
          <span class="el-dropdown-link">
             <img :src="`${user._avatar}?imageView2/1/w/200/h/200`" />
             <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>{{user.name}} - {{user._grade}}</el-dropdown-item>
            <el-dropdown-item divided><a href="javascript:;" @click="handleLogout();">安全退出</a></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!--container-->
    <div class="container">
      <div class="aside">
        <el-menu :default-active="$route.path" router unique-opened><!--v-if="$store.state.role"-->
          <template v-for="router in routers">
            <template v-if="router.meta.hideInMenu !== true && hasLeafNode(router.children)"> <!--hasLeafNode(router.children)-->
              <el-submenu :index="router.meta.index" v-if="router.children[0].path != ``" :key="router.meta.index">
                <template slot="title">
                  <i :class="router.meta.icon"></i>
                  <span slot="title">{{router.meta.title}}</span>
                </template>
                <template v-for="_router in router.children">
                  <el-submenu :index="_router.meta.index" v-if="_router.children && hasLeafNode(_router.children)">
                    <span slot="title">{{_router.meta.title}}</span>
                    <el-menu-item :index="`${router.path}/${_router.path}/${__router.path}`" v-for="__router in _router.children" :key="__router.meta.index" v-permission="__router.meta.rule"><!--v-permission="_router.meta.roles"-->
                      {{__router.meta.title}}
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item :index="`${router.path}/${_router.path}`" v-if="_router.children === undefined" v-permission="_router.meta.rule">
                    {{_router.meta.title}}
                  </el-menu-item>
                </template>
              </el-submenu>
              <template v-else>
                <el-menu-item :index="router.path" :key="router.meta.index" v-permission="router.meta.rule"><!--v-permission="router.meta.roles"-->
                  <i :class="router.meta.icon"></i>
                  <span slot="title">{{router.meta.title}}</span>
                </el-menu-item>
              </template>
            </template>
          </template>
        </el-menu>
      </div>
      <div class="main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import routers from '@/router/routers'
import {oneOf} from '@/libs/tool'
import {logout, getRule} from '@/libs/util'
export default {
  name: 'Container',
  data() {
    return {
      // logo: `${this.$config.cdn.image}`,
      user: {},
      routers
    }
  },
  mounted() {
    const self = this;
    self.$http.request({
      url: `user/user/readme`,
      success: (res) => {
        self.user = res.data
      }
    })
  },
  methods: {
    handleLogout() {
      logout()
      this.$router.push({
        path: this.$config.defaultURL
      })
    },
    hasLeafNode(routers) {
      const self = this
      const rules = getRule()
			return routers.some(router => {
        return !router.meta || !router.meta.rule ? true : oneOf(router.meta.rule, rules)
			})
    }
  }
}
</script>

<style lang="less">
@bgc: #fff;

.header {
  height: 80px;
  padding: 0 20px;
  background-color: @bgc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  display:flex;
  align-items: center;

  .logo {
    width: 120px
  }
  
  .nav {
    flex: auto;
    font-size: 16px
  }

  .avatar {
    width: 80px;
    text-align: right;
    img {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      vertical-align: middle;
      cursor: pointer
    }
  }

}

.container {
  padding: 0 20px;
  margin: 20px 0;
  display: flex;

  .aside {
    width: 200px;
    background-color: @bgc;
    margin-right: 40px;
    flex-shrink: 0 // 固定的 不缩小
  }

  .main {
    width: 0;
    flex: auto;
    min-height: 680px;
  }
}
</style>