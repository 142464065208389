const domainApi = `24hours.net.cn`
const domainCdn = `24hours.net.cn`

export default {
  systemName: `黑帆·北斗`,
  tokenName: `TOKEN_USER`,
  ruleName: `RULES`,
  defaultURL: `/`,
  homeURL: `/home`,
  upload: {
    qiniu: {
      putExtra: {
        mimeType: null
      },
      config: {
        useCdnDomain: true,
        region: `z1`
      }
    }
  },
  cdn: {
    image: `https://image.${domainCdn}`,
    file: `https://image.${domainCdn}`
  },
  request: {
		pro: {
			protocol: `https://`,
			node: {
				api: `api`
			}
		},
		dev: {
			protocol: `http://`,
			node: {
				api: 'api-dev'
			}
		}
  },
  domain: domainApi
}